<template>
  <div class="shipin container" v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <video
      :poster="videoPoster"
      :src="videoSrc"
      style="width: 100%; height: auto"
      controls
      x5-video-player-fullscreen="true"
      webkit-playsinline="true"
      x-webkit-airplay="true"
      playsinline="true"
      x5-playsinline
      width="100%"
    ></video>
    <div class="article">
      <div class="article-title">{{ article.title }}</div>
      <ul class="article-info">
        <li class="source">{{ article.source }}</li>
        <li class="author">{{ article.name }}</li>
        <li class="time">{{ article.time }}</li>
        <li>
          浏览：
          <span class="readNum">{{ article.readNum }}</span>
        </li>
      </ul>
    </div>
    <Comment :list="commentList" v-show="article.show_comment == 2 && commentList.length > 0" :id="id" :path="path" />
    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import OpenApp2 from "@/components/openApp2";
  import OpenApp from "@/components/openApp";
  import linkApp from "@/utils/linkApp";
  import Comment from "@/components/comment";
  import { getCzhArticle, getCzhTuijian, getCzhCommit } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  export default {
    name: "shipin",
    components: { Tanchuang, Kcz, OpenApp2, OpenApp, Comment },
    data() {
      return {
        canShow: false,
        id: "",
        path: "",
        commentList: [],
        article: {
          title: "",
          source: "",
          time: "",
          readNum: "",
        },
        videoSrc: "",
        videoPoster: "",
        share_config: {},
      };
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      async getDetail(id) {
        const res = await getCzhArticle(id);
        const data = res.data;
        this.path = data.type == 7 ? "czMiniVideoDetail" : "czVideoDetail";
        this.article.title = data.title;
        this.article.source = data.source;
        this.article.time = data.published_at;
        this.article.readNum = data.view_count;
        this.article.show_comment = data.show_comment;
        if (data.materials.video) {
          this.videoPoster = data.materials.video.cover;
          this.videoSrc = data.materials.video.src;
        } else {
          this.videoPoster = data.materials.minivideo.cover;
          this.videoSrc = data.materials.minivideo.src;
        }
        this.canShow = true;
        this.share_config = data.share_config;
        document.title = data.title;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
      async getCommit(id) {
        const res = await getCzhCommit(id);
        const data = res.data;
        this.commentList = data.slice(0, 3).map((val) => {
          return {
            avatar: val.avatar,
            name: val.nickname,
            time: val.created_at_human,
            like: val.likes_count,
            content: val.content,
            show_comment: val.show_comment,
          };
        });
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getDetail(this.id);
      this.getCommit(this.id);
    },
  };
</script>

<style lang="scss">
  .shipin {
    .article {
      margin-bottom: 50px;
    }
  }
</style>
